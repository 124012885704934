<template>
	<ReqBody title="Pending Requests" status="Pending Payment" />
</template>

<script>
	import ReqBody from "../components/adminRequestBody.vue";
	export default {
		components: { ReqBody },
	};
</script>
